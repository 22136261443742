/**
 * Create Order By
 *
 * @param {String} field
 * @param {Boolean | undefined | null} isDesc
 * @param {Boolean} disableFieldsAdapter
 *
 * @returns {object}
 */
export function createOrderBy (field, isDesc, disableFieldsAdapter) {
  const fields = {
    id: 'id',
    details: 'state'
  }

  if (disableFieldsAdapter) {
    return {
      type: 'field',
      field,
      direction: isDesc
        ? 'desc'
        : 'asc'
    }
  }

  return {
    type: 'field',
    field: fields[field] || field,
    direction: isDesc
      ? 'desc'
      : 'asc'
  }
}

/**
 * Build Query
 *
 * @param {object} pagination
 * @param {Boolean} disableFieldsAdapter
 *
 * @returns {object}
 */
export function buildQuery (pagination = {}, disableFieldsAdapter) {
  const keys = {
    rowsPerPage: 'per_page'
  }

  if (pagination.per_page && pagination.rowsPerPage) {
    pagination.per_page = pagination.rowsPerPage
  }

  // It is possible to have sortBy and order-by
  // Then we must use only order-by
  // And Order-by must exclude new field for order
  if (pagination['order-by']) {
    const fields = {
      id: 'id',
      details: 'state'
    }

    pagination['order-by'] = pagination['order-by'].filter(x => x.field !== (fields[pagination.sortBy] || pagination.sortBy))
  }

  const ignoredKeys = ['rowsNumber', 'descending']

  return Object.keys(pagination).reduce((acc, key) => {
    if (!pagination[key] || ignoredKeys.includes(key)) {
      return acc
    }

    // If key is order-by but we already has it then we merge them
    if (key === 'order-by' && acc['order-by']) {
      acc['order-by'] = [
        ...acc['order-by'],
        ...pagination['order-by']
      ]

      return acc
    }

    if (key === 'sortBy') {
      if (!acc['order-by']) {
        acc['order-by'] = []
      }

      acc['order-by'] = [
        ...acc['order-by'],
        createOrderBy(pagination.sortBy, pagination.descending, disableFieldsAdapter)
      ]

      return acc
    }

    acc[keys[key] || key] = pagination[key]
    return acc
  }, {})
}
