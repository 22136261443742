<template>
  <q-dialog v-model="isOpen" class="modal-md">
    <q-card>
      <q-card-section class="row items-center">
        <div class="text-h6 text-center text-capitalize">
          {{ title }}
        </div>

        <q-btn
          v-if="slide === 'items'"
          color="light-blue-9"
          text-color="white"
          icon="add"
          class=" q-ml-sm"
          size="sm"
          no-caps
          unelevated
          @click="create"
        />

        <q-btn
          v-else
          color="dark"
          text-color="white"
          class=" q-ml-sm"
          size="sm"
          :label="$t('Back')"
          no-caps
          unelevated
          @click="back"
        />

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>

      <q-card-section v-if="slide === 'items'" class="text-center">
        <q-table
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="items"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="isLoading"
          :filter="filter"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
              showing
              color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
              :props="props"
              class="clickable"
              @dblclick="onDBLClick(props.row)"
            >
              <q-td
                key="id"
                :props="props"
                class="text-subtitle1"
              >
                <strong>{{ props.row.id }}</strong>
              </q-td>

              <q-td
                key="object"
                :props="props"
              >
                {{ props.row.object }}
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>

      <q-card-section v-else class="q-pt-none">
        <permission :object="model.object" :role="role.id" @submit="handleSubmit" />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Utils
import { buildQuery } from '../../utils/query-utils'

// Components
import Permission from '../roles/Permission.vue'

export default {
  name: 'PermissionsModal',
  emits: ['submit'],
  components: {
    Permission
  },
  data () {
    return {
      isOpen: false,
      role: null,
      items: [],
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Object'),
          name: 'object',
          align: 'left'
        }
      ],
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      isLoading: false,
      filter: '',
      filters: [],
      slide: 'items',
      model: {}
    }
  },
  computed: {
    title () {
      if (!this.role) {
        return this.$t('Permissions')
      }

      return this.role.roleName + ' ' + this.$t('permissions')
    }
  },
  methods: {
    onDBLClick (item) {
      this.model = item
      this.slide = 'item'
    },
    handleSubmit (item) {
      let isFounded = false

      this.items = this.items.map(x => {
        if (x.id === item.id) {
          isFounded = true
          return item
        }

        return x
      })

      if (!isFounded) {
        this.items.push(item)
        this.slide = 'items'
      }
    },
    back () {
      this.slide = 'items'
    },
    create () {
      this.model = {
        role: this.role
      }

      this.slide = 'item'
    },
    close () {
      this.isOpen = false
    },
    open (role) {
      this.role = role
      this.isOpen = true
      this.filters = [
        { type: 'eq', field: 'role', value: role.id },
        { type: 'eq', field: 'type', value: 'object' },
        // { type: 'eq', field: 'state', value: 'active' }
      ]

      return this.onRequest({ pagination: { per_page: 25, page: 1 } })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      query.filter = [
        ...(query.filter || []),
        ...this.filters
      ]

      this.isLoading = true

      return this.$service.permission.getAll(query)
        .then(({ items, totalPages, page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsPerPage: query.per_page || 25,
            rowsNumber: totalItems
          }

          this.items = items

          return { items, totalPages, page, totalItems }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handlePermissionChange (item, value) {
      const permission = item.permission.includes(value)
        ? item.permission.filter(x => x !== value)
        : [...item.permission, value]

      return this.$service.permission.save({ permission }, item.id)
        .then(update => {
          this.items = this.items.map(x => {
            return x.id === update.id
              ? update
              : x
          })
        })
    }
  }
}
</script>
