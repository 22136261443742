<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 40vw; overflow: visible;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('New role') }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>

      <q-card-section class="text-center">
        <form-builder :schema="schema" />
      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
          color="dark"
          text-color="white"
          class="q-mr-sm"
          :label="$t('Close')"
          @click="close"
        />

        <q-btn
          color="light-blue-9"
          text-color="white"
          :label="$t('Save')"
          @click="save"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'RoleModal',
  emits: ['submit'],
  data () {
    return {
      isOpen: false,
      model: {
        roleId: '',
        roleName: ''
      }
    }
  },
  computed: {
    schema () {
      return {
        groups: [
          {
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Role ID'),
                field: 'roleId',
                value: this.model.roleId,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: roleId => {
                  this.model.roleId = roleId
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Name'),
                field: 'roleName',
                value: this.model.roleName,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: roleName => {
                  this.model.roleName = roleName
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    updateQueue (model) {
      this.queue = { ...model }
    },
    close () {
      this.isOpen = false
    },
    open (defaultData = {}) {
      this.isOpen = true
      this.model = {
        roleId: '',
        roleName: '',
        state: 'active',
        ...defaultData
      }
    },
    save () {
      const data = Object.entries(this.model).reduce((acc, [key, value]) => {
        acc[key] = value && typeof value === 'object' && value.id
          ? value.id
          : value
        return acc
      }, {})

      return this.$service.userRoles.save(data)
        .then(role => {
          this.$emit('submit', role)
          this.close()
          return role
        })
    }
  }
}
</script>
