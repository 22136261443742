<template>
  <div>
    <q-card>
      <q-card-section>
        <q-input
          standout="bg-teal text-white"
          filled
          v-model="objectModel"
          :required="true"
          :label="$t('Object')"
        />
      </q-card-section>
    </q-card>

    <div
      v-if="isLoading"
      class="row items-center justify-center"
      style="min-height: 200px;"
    >
      <q-spinner
        color="primary"
        size="4rem"
        class="q-mb-md"
      />
    </div>

    <div v-else>
      <div
        v-for="item in items"
        :key="item.id"
      >
        <permission-card
          v-if="item.state !== 'inactive'"
          :model="item"
          :disabled="isSaveLoading"
          class="q-my-sm"
          @change-model="handleChange"
          @delete="handleDelete"
        />
      </div>

      <q-btn
        color="light-blue-9"
        :label="$t('Add')"
        :disable="isSaveLoading"
        no-caps
        icon="add"
        @click="handleAdd('permission')"
      />
    </div>

    <div class="q-pa-xs text-center">
      <q-btn
        color="light-blue-9"
        no-caps
        :label="$t('Save')"
        :disable="isSaveLoading"
        @click="save"
      />
    </div>
  </div>
</template>

<script>
// Utils
import _ from 'lodash'
import { buildQuery } from '../../utils/query-utils'
import { convertEmbedded, difference } from '../../helpers/request-helpers'

// Components
import PermissionCard from './PermissionCard.vue'

export default {
  name: 'Permission',
  emits: ['submit'],
  components: {
    PermissionCard
  },
  props: {
    role: {
      type: [String, Number],
      default () {
        return ''
      }
    },
    object: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      objectModel: '',
      items: [],
      originalItems: [],
      isLoading: false,
      isSaveLoading: false
    }
  },
  computed: {
  },
  mounted () {
    this.objectModel = this.object

    if (!this.objectModel) {
      this.handleAdd('object')
      return
    }

    const pagination = {
      per_page: 250,
      page: 1,
      filter: [
        { type: 'eq', field: 'object', value: this.object },
        { type: 'eq', field: 'role', value: this.role },
        // { type: 'eq', field: 'state', value: 'active' }
      ],
      'order-by': [
        { type: 'field', field: 'object', direction: 'desc' }
      ]
    }

    this.onRequest({ pagination })
  },
  methods: {
    handleDelete (item) {
      const orItem = this.originalItems.find(x => x.id === item.id)

      if (!orItem) {
        this.items = this.items.filter(x => x.id !== item.id)
      } else {
        this.items = this.items.map(x => {
          if (x.id == item.id) {
            return {
              ...item,
              state: 'inactive'
            }
          }

          return x
        })
      }

      return this.items
    },
    handleAdd (type) {
      this.items.push({
        id: Math.floor(Math.random() * (9999999999) + 1),
        permission: [],
        states: [],
        properties: [],
        options: {},
        state: 'active',
        type
      })
    },
    handleChange (item) {
      let isExist = false

      this.items = this.items.map(x => {
        if (x.id == item.id) {
          isExist = true
          return item
        }

        return x
      })

      if (!isExist) {
        this.items = [...this.items, item]
      }

      const result = this.items.reduce((acc, item) => {
        if (item.type === 'object') {
          acc.permission = item
        } else if (item.type === 'permission') {
          const canRead = (item.permission || []).find(x => x === 'R')
          const canWrite = (item.permission || []).find(x => x === 'W')

          if (canRead) {
            acc.read = true
          }

          if (canWrite) {
            acc.write = true
          }
        }

        return acc
      }, { read: false, write: false, permission: null })
      
      if (result.permission) {
        result.permission.permission = []

        if (result.read) {
          result.permission.permission.push('R')
        }

        if (result.write) {
          result.permission.permission.push('W')
        }
      }

      return this.items
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      this.isLoading = true

      return this.$service.permission.getAll(query)
        .then(({ page, totalItems, items }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }

          this.items = items
          this.originalItems = _.cloneDeep(items)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    save () {
      this.isSaveLoading = true
      return this.saveAll(this.items, this.originalItems)
        .then(results => {
          this.$emit('submit', results.find(x => x.type === 'object'))
          this.items = results
          this.originalItems = _.cloneDeep(results)
        })
        .finally(() => {
          this.isSaveLoading = false
        })
    },
    saveAll (queue, originalQueue, results = []) {
      if (queue.length <= 0) {
        return Promise.resolve(results)
      }

      const orItem = originalQueue.find(x => x.id === queue[0].id)

      if (!orItem) {
        const data = {
          ...queue[0],
          id: undefined,
          object: this.objectModel,
          role: this.role
        }

        return this.$service.permission.save(data)
          .then(result => {
            results.push(result)
            return this.saveAll(queue.slice(1), originalQueue, results)
          })
      }

      const data = convertEmbedded(difference({ ...queue[0], object: this.objectModel }, orItem))

      if (Object.keys(data).length === 0) {
        results.push(queue[0])
        return this.saveAll(queue.slice(1), originalQueue, results)
      }

      if (data.state === 'inactive') {
        return this.$service.permission.delete(queue[0].id)
          .then(() => {
            return this.saveAll(queue.slice(1), originalQueue, results)
          })
      }

      return this.$service.permission.save(data, queue[0].id)
        .then(result => {
          results.push(result)
          return this.saveAll(queue.slice(1), originalQueue, results)
        })
    }
  }
}
</script>
