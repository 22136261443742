<template>
  <div>
    <div class="row items-center">
      <div
        v-if="arrow"
        class="q-px-sm border-top"
      />

      <q-card class="border-top border-top--teal row col">
        <q-card-actions align="center" class="q-pl-sm">
          <q-icon
            :name="isOpen ? 'expand_less' : 'expand_more'"
            class="clickable btn--fade"
            size="20px"
            @click="toggle"
          />
        </q-card-actions>

        <q-card-section class="col">
          <small class="text-grey-7">
            {{ $t('Role') + ': ' + role.id }}
          </small>

          <div class="text-subtitle1 clickable">
            {{ role.roleName }}

            <q-icon
              name="edit"
              class="q-ml-sm"
            />

            <q-popup-edit
              :model-value="role.roleName"
              :title="$t('Edit role name')"
            >
              <div class="row items-center">
                <q-input
                  v-model="roleName"
                  class="col q-mr-sm"
                  dense
                  autofocus
                  counter
                />

                <q-btn
                  color="light-blue-9"
                  text-color="white"
                  :label="$t('Save')"
                  size="sm"
                  no-caps
                  unelevated
                  @click="saveName"
                />
              </div>
            </q-popup-edit>
          </div>

          <div class="q-mt-sm">
            <q-btn
              color="negative"
              text-color="white"
              :label="$t('Permissions')"
              size="sm"
              class="q-mr-sm"
              no-caps
              unelevated
              @click="openPermissions"
            />

            <q-btn
              color="light-blue-9"
              text-color="white"
              :label="$t('Add child role')"
              size="sm"
              no-caps
              unelevated
              @click="create"
            />
          </div>
        </q-card-section>
      </q-card>
    </div>

    <div ref="container" class="row">
      <div
        v-if="hasLine"
        class="border-right"
        :style="`padding-left: ${padding}px;`"
      />

      <div class="col">
        <role-row
          v-if="isOpen"
          ref="roleRow"
          :filter="filter"
          :direction="'column'"
          :arrow="true"
          :level="level + 1"
          @on-load="handleLoad"
        />
      </div>
    </div>

    <permission-modal ref="permissionModal" />

    <role-modal ref="roleModal" @submit="handleRoleSubmit" />
  </div>
</template>

<script>
// Components
import PermissionModal from '../modals/PermissionModal.vue'
import RoleModal from '../modals/RoleModal.vue'

export default {
  name: 'RoleCard',
  components: {
    PermissionModal,
    RoleModal
  },
  emits: ['submit'],
  props: {
    level: {
      type: Number,
      default () {
        return 1
      }
    },
    arrow: {
      type: Boolean,
      default () {
        return false
      }
    },
    role: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isOpen: false,
      hasLine: false,
      roleName: ''
    }
  },
  computed: {
    padding () {
      return this.level * 16
    },
    filter () {
      return [
        { type: 'eq', field: 'parent', value: this.role.id }
      ]
    }
  },
  mounted () {
    this.roleName = this.role.roleName
  },
  methods: {
    handleRoleSubmit (role) {
      if (this.$refs.roleRow) {
        this.$refs.roleRow.handleSubmit(role)
      }
    },
    openPermissions () {
      this.$refs.permissionModal.open(this.role)
    },
    toggle () {
      this.isOpen = !this.isOpen
    },
    saveName () {
      return this.$service.userRoles.save({ roleName: this.roleName }, this.role.id)
        .then(item => {
          this.$emit('submit', item)
          this.roleName = item.roleName
          return item
        })
    },
    create () {
      this.$refs.roleModal.open({ parent: this.role })
    },
    handleLoad (items) {
      this.hasLine = items.length > 0
    }
  }
}
</script>
