<template>
  <q-card>
    <q-card-section class="text-subtitle1 q-py-xs text-capitalize">
      {{ $t(model.type) }}
    </q-card-section>

    <q-card-section class="q-py-xs">
      <form-builder :schema="schema" />
    </q-card-section>

    <q-card-actions v-if="model.type !== 'object'" class="q-pt-xs">
      <q-btn
        color="negative"
        no-caps
        :label="$t('Delete')"
        :disable="disabled"
        @click="handleDelete"
      />
    </q-card-actions>
  </q-card>
</template>

<script>
export default {
  name: 'PermissionCard',
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    model: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      permissions: ['R', 'W']
    }
  },
  computed: {
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'tag',
                label: this.$t('Permissions'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                value: this.model.permission,
                options: this.permissions,
                disabled: this.disabled || this.model.type === 'object',
                customListItem: (row) => {
                  return row
                },
                onRemove: (value) => {
                  this.$emit('change-model', {
                    ...this.model,
                    permission: (this.model.permission || []).filter(x => x !== value)
                  })
                },
                onAdd: (value) => {
                  if (Array.isArray(value)) {
                    return
                  }

                  let isExist = false

                  const permission = this.model.permission.filter(val => {
                    if (val === value) {
                      isExist = true
                      return false
                    }

                    return true
                  })

                  if (!isExist) {
                    permission.push(value)
                  }

                  this.$emit('change-model', { ...this.model, permission })
                }
              },
              {
                if: this.model.type !== 'object',
                type: 'tag',
                value: this.model.states || [],
                wrapperStyleClasses: 'col-12 q-pa-xs',
                label: this.$t('States'),
                disabled: this.disabled,
                onSubmit: (value) => {
                  if (!value) {
                    return
                  }

                  this.$emit('change-model', {
                    ...this.model,
                    states: [
                      ...(this.model.states || []),
                      value
                    ]
                  })
                },
                onRemove: (value) => {
                  this.$emit('change-model', {
                    ...this.model,
                    states: (this.model.states || []).filter(x => x !== value)
                  })
                }
              },
              {
                if: this.model.type !== 'object',
                type: 'tag',
                value: this.model.properties || [],
                wrapperStyleClasses: 'col-12 q-pa-xs',
                label: this.$t('Properties'),
                disabled: this.disabled,
                onSubmit: (value) => {
                  if (!value) {
                    return
                  }

                  this.$emit('change-model', {
                    ...this.model,
                    properties: [
                      ...(this.model.properties || []),
                      value
                    ]
                  })
                },
                onRemove: (value) => {
                  this.$emit('change-model', {
                    ...this.model,
                    properties: (this.model.properties || []).filter(x => x !== value)
                  })
                }
              },
              {
                type: 'switch',
                label: this.$t('Required'),
                value: !!(this.model.options || {}).required,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: required => {
                  const options = {
                    ...this.model.options || {},
                    required
                  }

                  this.$emit('change-model', {
                    ...this.model,
                    options
                  })
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    handleDelete () {
      this.$emit('delete', this.model)
    }
  }
}
</script>
